import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Page,
  SectionHero,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  TabNav,
  SectionTrendingVendors,
  SectionTopVendors,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';

import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import facebookImage from '../../assets/vt-logo.png';
import { addOrRemoveToConnected } from '../../ducks/user.duck';

import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    intl,
    params,
    history,
    location,
    listings,
    venueListings,
    pageName,
    allCities,
    categories,
    currentUser,
    subCategories,
    isAuthenticated,
    scrollingDisabled,
    onAddOrRemoveToConnected,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const handleSubmit = (values) => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...originMaybe,
      address: search,
      bounds,
    };
    return history.push(createResourceLocatorString('AlgoliaSearchPage', routeConfiguration(), {}, searchParams));
  }

  const selectedCategory = categories && categories.length
    ? params.categoryName
      ? categories.filter(c => params.categoryName == c.key)[0]
      : subCategories && subCategories.length
        ? categories.filter(c => subCategories[0].parentId == c.id)[0]
        : ''
    : '';

  const tabs = (categories && categories.map(c => {
    return ({
      text: (
        <span className={params.categoryName == c.key ? css.tabsSelected : css.tabsText}>
          {c.label}
        </span>
      ),
      selected: params.categoryName == c.key,
      linkProps:
      // isAuthenticated
      //   ? 
      {
        name: 'HomePage',
        params: { categoryName: c.key },
      }
      // : {
      //   name: 'AlgoliaSearchPage',
      //   params: { categoryName: c.key },
      //   to: {
      //     search: `refinementList[categoriesLabel][0]=${c.label}&page=1&configure[aroundLatLngViaIP]=true&configure[aroundRadius]=all&configure[hitsPerPage]=8`
      //   }
      // },
    });
  })) || [];

  const nav = tabs ? (
    <TabNav className={css.homeTabs} pageName={pageName} tabs={tabs} />
  ) : null;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.mainWrapper}>
          <div className={css.mainContent}>
            {/* (currentUser && currentUser.id) ?  : css.noSpace */}
            {/* {(currentUser && currentUser.id)
              ?  */}
            {/* <div className={css.categoriesTabs}>
              {nav}
            </div> */}
            {/* : null} */}
            <div className={css.heroContainer}>
              <SectionHero
                className={css.hero}
                intl={intl}
                history={history}
                location={location}
                subCategories={subCategories}
                onSearchSubmit={handleSubmit}
                selectedCategory={selectedCategory}
                currentUser={currentUser}
                categories={categories}
              />
            </div>

            {/* {(currentUser && currentUser.id) ?  */}
            <hr className={css.lineDivider} />
            {/* : null} */}
            <ul className={css.sections}>
              <li className={css.section}>
                <div className={css.sectionContentFirstChild}>
                  {listings && listings.length
                    ? <SectionTrendingVendors
                      trendingVendors={listings}
                      currentUser={currentUser}
                      categories={categories}
                      subCategories={subCategories}
                      isAuthenticated={isAuthenticated}
                      onAddOrRemoveToConnected={onAddOrRemoveToConnected}
                    />
                    : null}
                </div>
              </li>
              <li className={css.secondSection}>
                <div className={css.sectionContentFirstChild}>
                  {venueListings && venueListings.length
                    ? <SectionTrendingVendors
                      trendingVenues={venueListings}
                      currentUser={currentUser}
                      categories={categories}
                      subCategories={subCategories}
                      isAuthenticated={isAuthenticated}
                      onAddOrRemoveToConnected={onAddOrRemoveToConnected}
                    />
                    : null}
                </div>
              </li>
              {/* {allCities && allCities.length
                ? allCities.map(cities => (
                  <li key={cities.query} className={css.secondSection}>
                    <div className={css.sectionContentFirstChild}>
                      {cities && cities.hits && cities.hits.length >= 4
                        ? <SectionTopVendors
                          currentUser={currentUser}
                          topVendors={cities.hits}
                          city={cities.query}
                          categories={categories}
                          subCategories={subCategories}
                          isAuthenticated={isAuthenticated}
                          onAddOrRemoveToConnected={onAddOrRemoveToConnected}
                        />
                        : null}
                    </div>
                  </li>))
                : null} */}
            </ul>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    categories,
    currentUser,
    subCategories,
    currentUserListing,
    currentUserListingFetched
  } = state.user;
  const {
    cities,
    citiesInProgress,
    venuesResultIds,
    currentPageResultIds,
    allCitiesSuccess,
    allCities,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const venueListings = getListingsById(state, venuesResultIds);

  return {
    listings: pageListings,
    venueListings,
    scrollingDisabled: isScrollingDisabled(state),
    categories,
    currentUser,
    subCategories,
    isAuthenticated,
    cities,
    citiesInProgress,
    currentUserListing,
    currentUserListingFetched,
    allCitiesSuccess,
    allCities
  };
};

const mapDispatchToProps = dispatch => ({
  onAddOrRemoveToConnected: (listingId, isAddedPreferred, businessName) => dispatch(addOrRemoveToConnected(listingId, isAddedPreferred, businessName))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
